import React, {Component} from 'react';

import '../../assets/css/TextBlurb.scss';

interface ITextBlurbProps {
    title: string;
}

class TextBlurb extends Component<ITextBlurbProps> {
    render() {
        return (
            <div className="TextBlurbContainer">
                <h1 className='TextBlurbTitle'>
                    {this.props.title}
                </h1>
                <hr/>
                <div className="TextBlurbChildren">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default TextBlurb;