import * as actionTypes from './ActionTypes';

/**
 * Active the secret state for a fun easter egg
 */
export const activateSecret = () => ({
    type: actionTypes.SECRET_STATE,
});

export const lockNavigationBar = () => ({
    type: actionTypes.LOCK_NAVIGATION_BAR,
});

export const unlockNavigationBar = () => ({
    type: actionTypes.UNLOCK_NAVIGATION_BAR,
});