import React, {Component} from 'react';

import Logo from "./Logo";
import '../../assets/css/NavigationBar.scss';
import {Link} from "react-router-dom";

interface INavigationBarState {
    lockNavigation: boolean
}

class NavigationBar extends Component<any, INavigationBarState> {
    constructor(props: any) {
        super(props);
        window.addEventListener('scroll', this.navBarCalculation);
        this.state = {
            lockNavigation: false
        }
    }

    navBarCalculation = (event: any) => {
        const yPosition: number = window.scrollY;
        if (yPosition > 160) {
            this.setState({
                lockNavigation: true
            })
        } else if (yPosition < 161) {
            this.setState({
                lockNavigation: false
            })
        }
    };


    render() {
        const lockNavigation: boolean = this.state.lockNavigation;
        const lockStyle: string = lockNavigation ? 'NavigationBarFixed' : '';
        return (
            <nav className="NavigationBarBase">
                <Logo/>
                <div className={"NavigationBar " + lockStyle}>
                    <hr/>
                        <Link to="/">
                            <h1>Home</h1>
                        </Link>
                        <div className="Spacer">
                            -
                        </div>
                        <Link to="/adventofathria">
                            <h1>
                                Advent of Athria
                            </h1>
                        </Link>
                        <div className="Spacer">
                            -
                        </div>
                        <Link to="/adventuresofathria">
                            <h1>Adventures of Athria</h1>
                        </Link>
                    <hr/>
                </div>
            </nav>);
    }
}
export default NavigationBar;