import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import NavigationBar from "./components/navigationBar/NavigationBar";
import TextBlurb from "./components/sdk/TextBlurb";

import './assets/css/App.scss'
import CustomButton from "./components/sdk/CustomButton";

class App extends Component {


    render() {
        const currentDate: Date = new Date();
        const copyrightBlock: any = (
            <div className="CopyrightBlock">
                <h4>Copyright &copy; {currentDate.getFullYear()} Holucid Interactive LLC</h4>
                <h4>All Rights Reserved</h4>
            </div>
        );
        // @ts-ignore
        return (
              <Router>
                  <div className="App">
                      <NavigationBar/>
                  </div>
                  <Route exact path="/" render={() => (
                      <div>
                          <TextBlurb title='Welcome'>
                              <p>
                                  Welcome to Holucid Interactive's website. We are a company working towards bringing expansive gaming experiences to consumers.
                                  Please click on any of the topics in the navigation bar to get more details, or to purchase our products.
                              </p>
                          </TextBlurb>
                          {copyrightBlock}
                      </div>
                  )}/>
                  <Route exact path="/index.html" render={() => (
                      <div>
                          <TextBlurb title='Welcome'>
                              <p>
                                  Welcome to Holucid Interactive's website. We are a company working towards bringing expansive gaming experiences to consumers.
                                  Please click on any of the topics in the navigation bar to get more details, or to purchase our products.
                              </p>
                          </TextBlurb>
                          {copyrightBlock}
                      </div>
                  )}/>
                  <Route exact path="/adventofathria" render={() => (
                      <div>
                          <TextBlurb title='Advent of Athria'>
                              <p>
                                  The Advent of Athria project is a tabletop Role Playing Game project set in a custom universe.
                                  This classless, d6 pool based system offers extensive customization for player characters, as well as a very open setting for game masters.
                              </p>
                              <p>
                                  Stay tuned for more news about Advent of Athria, which is in progress and set to release in late 2022.
                              </p>
                              <p>
                                  For any question or inquiries, please email us at: admin@holucidinteractive.com.
                              </p>
                          </TextBlurb>
                          {copyrightBlock}
                      </div>
                  )}/>
                  <Route exact path="/adventuresofathria" render={() => (
                      <div>
                          <TextBlurb title='Adventures of Athria'>
                              <p>
                                  The Adventures of Athria project is a new role playing game set in a universe built from the ground up.
                              </p>
                          </TextBlurb>
                          <TextBlurb title='Where to buy?'>
                              <p>
                                  Currently you can purchase our game here.
                              </p>
                              <div>
                                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                      <input type="hidden" name="cmd" value="_s-xclick"/>
                                          <input type="hidden" name="hosted_button_id" value="F6XH8U5FP9HRE"/>
                                              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                                                  <img alt="" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                                  </form>
                              </div>
                          </TextBlurb>
                          <TextBlurb title='Where to download?'>
                              <p>
                                  To download the game, please click the button below and follow these instructions:
                              </p>
                              <p>
                                  1 : Click the link below and download the Adventures of Athria.zip file at the link.
                              </p>
                              <p>
                                  2 : Extract the zip to a folder on your computer and open that folder. This will be password protected, and you will receive a password upon purchase to open zip file.
                              </p>
                              <p>
                                  3 : Inside the folder you should find a Game executable file titled 'Game.exe'
                              </p>
                              <p>
                                  4 : Run the executable to play!
                              </p>
                              <CustomButton buttonText="Download Here" clickCallbackFunction={(e => {window.open('https://drive.google.com/open?id=1oa0My55g5hWGIhYNxoWBs1E1PHghv4Kx')})}/>
                          </TextBlurb>
                          <TextBlurb title='Questions or Feedback'>
                              <p>
                                  For any question or inquiries, please email us at: admin@holucidinteractive.com.
                              </p>
                          </TextBlurb>
                          {copyrightBlock}
                      </div>
                  )}/>
              </Router>
        );
    }
}

export default App;