import { LOCK_NAVIGATION_BAR, UNLOCK_NAVIGATION_BAR } from "../ActionTypes";

const initialState = {
    lockNavigationBar: false,
};

export default (state:any = initialState, action: any) => {
    switch(action.type) {
        case LOCK_NAVIGATION_BAR:
            return {
                ...state,
                lockNavigationBar: true,
            }
        case UNLOCK_NAVIGATION_BAR:
            return {
                ...state,
                lockNavigationBar: false,
            }
        default:
            return state;
    }
}
