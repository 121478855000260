import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import {connect} from "react-redux";
import {activateSecret} from "../redux/Actions";
import {getSecretState} from "../redux/Selectors";

import logoImage from '../../assets/images/HolucidInteractive_Logo_Flat_062716.png';
import secretLogo from '../../assets/images/HolucidInteractive_Logo_OutlineGrey_062716.png';
import textImage from '../../assets/images/HolucidInteractive_Logo_Type_062716.png';
import '../../assets/css/Logo.scss';

const mapStateToProps = (state: any, ownProps?: any) => {
    const secretState = getSecretState(state);
    return { secretState };
};

class Logo extends Component {
    private readonly allowedKeys: any = {
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        65: 'a',
        66: 'b'
    };
    private readonly sequence: string[] = [
        'up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'
    ];
    private currentPosition: number = 0;

    constructor(props: any) {
        super(props);
        document.addEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event: any) => {
         const key: string = this.allowedKeys[event.keyCode];
         const requiredKey: string = this.sequence[this.currentPosition];
         if (key === requiredKey) {
             this.currentPosition++;
             if (this.currentPosition === this.sequence.length) {
                 //@ts-ignore
                 this.props.activateSecret();
             }
         } else {
             this.currentPosition = 0;
         }
    };

    render() {
        //@ts-ignore
        const activated: boolean = this.props.secretState;
        //@ts-ignore
        const logoType: Element = activated ?
            <img className="Logo" src={secretLogo} alt="Animated Logo" /> :
            <img className="Logo" src={logoImage} alt="Logo" />;
        return (
            <div>
                <Link to="/">
                    {logoType}
                </Link>
                <img className="LogoText" src={textImage} alt="LogoText"/>
            </div>)
    }
}
export default connect(mapStateToProps,
    {activateSecret})(Logo);