import React, {Component} from 'react';

import '../../assets/css/CustomButton.scss';

interface IButtonProps {
    buttonText: string;
    clickCallbackFunction: (e: any) => void;
}

class CustomButton extends Component<IButtonProps> {
    private handleClick(event:any) {
        this.props.clickCallbackFunction(event);
    }

    render() {
        return (
            <button className="CustomButton" onClick={(e:any) => this.handleClick(e)}>
                {this.props.buttonText}
            </button>
        )
    }
}

export default CustomButton;