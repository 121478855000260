import { SECRET_STATE } from '../ActionTypes'

const initialState = {
    secretState: false,
};

export default (state:any = initialState, action: any) => {
    if (action.type === SECRET_STATE) {
        return {
            ...state,
            secretState: !state.secretState,
        }
    }
    return state;
}